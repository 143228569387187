.p-relative {
  position: relative;
  z-index: 1;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

.flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.flex-end {
  justify-content: flex-end;
}
