.loader {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--beige);
  opacity: 0;
  visibility: hidden;
  transform-origin: left;
  transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
}

.loader.changing {
  opacity: 1;
  visibility: visible;
}

.loader.changing .layer1, 
.loader.changing .layer2 {
  transform: scaleX(1);
}

.loader.changing .layer3 {
  transform: translateX(-50%) scaleX(1) skew(0);
  transition-delay: .4s;
}

.loader.changing .loading_icon {
  opacity: 1;
}

.layer1, .layer2, .layer3 {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  transform: scaleX(0);
  transition: transform .2s ease-in-out;
}

.layer1 {
  left: 0;
  background-color: var(--blue);
  transform-origin: left;
}

.layer2 {
  right: 0;
  background-color: var(--yellow-500);
  transform-origin: right;
}

.layer3 {
  background-color: var(--beige);
  left: 50%;
  width: 100%;
  min-width: 100px;
  transform-origin: center;
  transform: translateX(-50%) scaleX(0) skew(45deg);
  transition-delay: .3s;
}

@media (width <= 768px) {
  .layer1, .layer2 {
    width: 100%;
    height: 50%;
    transform: scaleY(0);
  }
  
  .layer1 {
    transform-origin: top;
  }

  .layer2 {
    top: 50%;
    transform-origin: bottom;
  }

  .loader.changing .layer1, 
  .loader.changing .layer2 {
    transform: scaleY(1);
  }

  .layer3 {
    transform: translateX(-50%) scaleX(0);
  }
}

.loading_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 100px;
  height: 100px;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  transition-delay: .4s;
}

.loading_icon div {
  position: absolute;
  border: 4px solid var(--blue);
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading_icon div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
